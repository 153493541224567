import * as req from "./https";

export const PostReq = async (path, body) => {
  try {
    let res = await req.http.post(path, body);
    return res;
  } catch (error) {
    return error;
  }
};

export const GetReq = async (path) => {
  try {
    let res = await req.http.get(path);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
