import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LifeStyle from "./Components/LifeStyle";
import AxisPage from "./Components/Axis-bank/AxisPage";
import LifestyleCheckout from "./Components/LifestyleCheckout";
import ChooseBrand from "./Components/ChooseBrand";
import Products from "./Components/Axis-bank/Products";
import CheckoutPage from "./Components/Axis-bank/CheckoutPage";
import Congratulation from "./Components/Axis-bank/Congratulation";
import { ToastContainer } from "react-toastify";
import TermsAndConditionPage from "./Components/Axis-bank/TermsAndConditionPage";
import { useSelector } from "react-redux";
import Page404 from "./Components/Page404";
import Home from "./pages/Home";
import Squid from "./pages/Squid";
import Crcode from "./pages/Crcode";
import Nsdlcard from "./pages/Nsdlcard";
import Enquiry from "./pages/Enquiry";
import Aboutus from "./pages/Aboutus";
import { ContactUS } from "./pages/ContactUS";
import { HelmetProvider } from "react-helmet-async";


function App() {
  const toggleModal = useSelector((state) => state?.toggle?.isLoggedIn);
  console.log(toggleModal);
  return (
    <HelmetProvider>
      <Router>
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          theme="light"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/lifestyle" element={<LifeStyle />} />
          <Route exact path="/shoppers-stop" element={<LifeStyle />} />
          <Route
            exact
            path="/redeem/axisbank"
            element={toggleModal ? <TermsAndConditionPage /> : <AxisPage />}
          />
          <Route
            exact
            path="/redeem/axisbank/products"
            element={toggleModal ? <TermsAndConditionPage /> : <Products />}
          />
          <Route
            exact
            path="/redeem/axisbank/checkout"
            element={toggleModal ? <TermsAndConditionPage /> : <CheckoutPage />}
          />
          <Route
            exact
            path="/lifestyle-checkout"
            element={<LifestyleCheckout />}
          />
          <Route
            exact
            path="/shoppers-checkout"
            element={<LifestyleCheckout />}
          />

          <Route exact path="/congratulation" element={<Congratulation />} />
          <Route path="/squid" element={<Squid />} />
          <Route path="/credence-code" element={<Crcode />} />
          <Route path="/credence-visa-card" element={<Nsdlcard />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/about-us" element={<Aboutus />} />

          <Route path="*" element={<Page404 />} />
        </Routes>
        {/* <Navigate from="/" to="/lifestyle" /> */}
      </Router>
    </HelmetProvider>
  );
}

export default App;
