import React, { useState, useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import Trust from "../Components/Trust";

import Catalogues from "../Components/Catalogues";
import Companies from "../Components/Companies";
import Corporate from "../Components/Corporate";
import Nsdl from "../Components/Nsdl";
import flipkart from "../assets/flipkart.png";
import Nykaa from "../assets/Nykaa.png";
import Swiggy from "../assets/swiggy.png";
import yatra from "../assets/Yatra.png";
import fasttrack from "../assets/Fasttrack.png";
import helios from "../assets/Helios.png";
import levis from "../assets/Levis.png";
import Makemytrip from "../assets/Makemytrip.png";
import Pcj from "../assets/PCJ.png";
import Serai from "../assets/Serai.png";
import phone from "../assets/phone.svg";
import calender from "../assets/calender.svg";
import hero from "../assets/gift2.gif";
// import Taneria from "../assets/Taneria.png";
import Marquee from "react-fast-marquee";
import emoji from "../assets/512.gif";
import ExploreSolution from "../Components/ExploreSolution";
import HelmetWrapper from "../Components/HelmetWrapper";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const Home = () => {
  const [activeTab, setActiveTab] = useState("B2B");
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <MainLayout>
      <HelmetWrapper
        title="Credence Rewards - Earn and Redeem Exciting Rewards"
        description="Join Credence Rewards to earn points and redeem them for exciting rewards. Discover exclusive offers, deals, and a wide range of products to choose from. Start earning today and enjoy the benefits of our loyalty program!"
        keywords="E-gift card,gift cards, personal gifting,  Corporate Gifting Solution, digital gift cards, Gift, exclusive offers, deals, Credence Rewards, Employee Appreciation, corporate rewards, exciting rewards"
        ogTitle="Credence Rewards - Earn and Redeem Exciting Rewards"
        ogDescription="Join Credence Rewards to earn points and redeem them for exciting rewards. Discover exclusive offers, deals, and a wide range of products to choose from. Start earning today and enjoy the benefits of our loyalty program!"
        ogUrl="https://credencerewards.co.in/"
        ogImage="https://credencerewards.co.in/path-to-image.jpg" // Replace with the actual path to your image
        twitterTitle="Credence Rewards - Earn and Redeem Exciting Rewards"
        twitterDescription="Join Credence Rewards to earn points and redeem them for exciting rewards. Discover exclusive offers, deals, and a wide range of products to choose from. Start earning today and enjoy the benefits of our loyalty program!"
        twitterImage="https://credencerewards.co.in/path-to-image.jpg" // Replace with the actual path to your image
        canonicalUrl="https://credencerewards.co.in/"
      />
      <div className="">
        <div className=" section1 w-[100%] text-black relative  ">
          <div className="md:flex relative w-full hidden  ">
            <div className="bg-[#0B63E5]/20  blur-[100px] absolute -top-64 z-10 left-0 w-[10%] md:w-96 md:h-96 rounded-full"></div>
            <div className="bg-[#F249C8]/20  absolute blur-[100px] -top-64  left-[45%]  z-10 right-[50%] w-[10%] md:w-96 md:h-96 rounded-full"></div>
            <div className="bg-[#F249C8]/20  absolute blur-[100px] -top-64  left-[25%]  z-10 right-[40%] w-[10%] md:w-96 md:h-96 rounded-full"></div>
            <div className="bg-[#0B63E5]/20  absolute blur-[100px] -top-64 right-0 w-[10%] md:w-96 md:h-96 z-10 rounded-full"></div>
          </div>
          <div className="  relative  pb-14">
            <motion.h1
              className="text-center pt-14  md:pt-16 font-bold relative z-40 capitalize text-[#212529] text-[35.5px] md:text-[72px] leading-[49.5px] md:leading-[99px] font-source"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.75, delay: 0.1 }}
            >
              Enhance Your Gifting Experience
              <div className="flex justify-center gap-5 items-center">
                <div>
                  with{" "}
                  <span className="bg-clip-text underline bg-[#594DF1] text-transparent">
                    Credence Rewards
                  </span>
                </div>
              </div>
            </motion.h1>
            <h3 className="text-center text-[#212529] relative z-40 text-[18px]  md:text-[24px] mb-10 mt-4 w-[90%] md:w-[45%]  leading-[28.5px] md:leading-[33px] mx-auto">
              Integrate personal gifting with corporate rewards through Credence
              Rewards, transforming the way you recognize and celebrate
              achievements
            </h3>
            <div className="flex relative z-40  justify-center gap-9 items-center">
              <Link to={"/contact-us"}>
                <button className=" flex justify-center shadow-md gap-2 items-center CTA text-base font-semibold border   font-Source    hover:shadow-xl transition-colors delay-100   rounded-full p-2 px-3">
                  <img className="w-4" src={phone} alt="" /> Get a Call
                </button>
              </Link>
            </div>
            <div className="flex justify-center gap-2 items-center mt-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
                  fill="#F249C8"
                />
                <path
                  d="M3 16.5L12 21.75L21 16.5"
                  stroke="#F249C8"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 12L12 17.25L21 12"
                  stroke="#F249C8"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
                  stroke="#F249C8"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Gift with purpose, delight with every choice.</p>
            </div>
          </div>
        </div>
      </div>
      <Marquee gradient={true} pauseOnHover={true} className="mx-auto">
        <img className="w-48 h-52 mx-2" src={yatra} alt="yatra e-gift card" />
        <img className="w-48 h-52 mx-2" src={Pcj} alt="Pcj e-gift card" />
        <img className="w-48 h-52 mx-2" src={Serai} alt="serai e-gift card" />
        <img
          className="w-48 h-52 mx-2"
          src={fasttrack}
          alt="fasttrack e-gift card"
        />
        <img
          className="w-48 h-52 mx-2"
          src={flipkart}
          alt="flipkart e-gift card"
        />
        <img className="w-48 h-52 mx-2" src={Swiggy} alt="swiggy e-gift card" />
        <img className="w-48 h-52 mx-2" src={levis} alt="levis e-gift card" />
        <img className="w-48 h-52 mx-2" src={helios} alt="helios e-gift card" />
        <img className="w-48 h-52 mx-2" src={Nykaa} alt="Nykaa e-gift card" />
      </Marquee>
      <Corporate />
      <ExploreSolution />

      <Companies />
      {/* <Flexibilty /> */}

      <Trust />
    </MainLayout>
  );
};

export default Home;
