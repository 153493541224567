import React, { useEffect, useState } from "react";
import Footer from "./Axis-bank/Footer";
import PowerLogo from "../Components/Axis-bank/poweredby.png";
import { Link } from "react-router-dom";

function ChooseBrand() {
  const [isOpen, setIsOpen] = useState(true);

 useEffect(() => {
 window.location="https://credencerewards.com/"
 }, [])
 

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="flex h-screen  flex-col justify-start      ">
      <div className="mt-[45px]">
        <img className="w-full  " src="../assets/banner.png" alt="" />
      </div>
        <div className="flex justify-center gap-24 mt-5 flex-wrap">
          <div className="max-w-[23rem]  cursor-pointer rounded-lg ">
            <Link
              to="/lifestyle"
              className="flex justify-center items-center mt-2"
            >
              <img
                className=" shadow-md  w-full"
                src="../assets/lifestyle-card.png"
                alt=""
              />
            </Link>
          
          </div>
          <div className="max-w-[23rem] cursor-pointer rounded-lg">
            <Link
              to="/shoppers-stop"
              className="flex justify-center items-center mt-2"
            >
              <img
                className=" shadow-md  w-full"
                src="../assets/shoppersStop-card.png"
                alt=""
              />
            </Link>
          
          </div>
        </div>
      </div>
      {/* <div className=" w-full text-center mt-5 bg-white   ">
        <div className="flex justify-end items-baseline">
          <span className="text-[12px] mr-2 text">Powered by </span>
          <img
            src={PowerLogo}
            alt="credence-logo"
            className="h-10 w-10 md:mr-24 mr-4"
          />
        </div>
        <p className="text-sm text-slate-400 ">
          Coyright &copy; 2023. All rights reserved by Credence rewards.
        </p>
      </div> */}
        <div className={`modal ${isOpen ? 'block' : 'hidden'}`}>
      <div className="modal-overlay"></div>
      <div className="modal-container">
        {/* Modal content */}
        <button className="modal-close" onClick={()=>closeModal()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="modal-content">
          {/* Your content goes here */}
          <p>
            From 15th Dec 2023, This panel has been moved to credencerewards
            website. All these will be done from{' '}
            <a href="http://www.credencerewards.com" target="_blank" rel="noopener noreferrer">
              http://www.credencerewards.com
            </a>
            . Which benefits the users in terms of login one time and then
            order as many vouchers without filling vouchers repeatedly.
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default ChooseBrand;
