import React from 'react'

function Loader() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-opacity-50 bg-black z-50">
    <div className="custom-loader">
      <div className="w-30 h-30 aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
        <div className="py-1 w-full flex items-center justify-center">
        <svg
    version="1.0"
    width="128px" // Increase width to 128px
    height="128px" // Increase height to 128px
    viewBox="0 0 128 128"
    className="w-12 h-12 text-black animate-spin"
    fill="none"
  >
    <g>
      <circle cx="16" cy="64" r="16" fill="#000000" />
      <circle
        cx="16"
        cy="64"
        r="14.344"
        fill="#000000"
        transform="rotate(45 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="12.531"
        fill="#000000"
        transform="rotate(90 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="10.75"
        fill="#000000"
        transform="rotate(135 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="10.063"
        fill="#000000"
        transform="rotate(180 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="8.063"
        fill="#000000"
        transform="rotate(225 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="6.438"
        fill="#000000"
        transform="rotate(270 64 64)"
      />
      <circle
        cx="16"
        cy="64"
        r="5.375"
        fill="#000000"
        transform="rotate(315 64 64)"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
        calcMode="discrete"
        dur="1500ms" // Increase duration to slow down the animation
        repeatCount="indefinite"
        keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
      />
    </g>
  </svg>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Loader