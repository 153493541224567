import React from "react";
import catalog from "../assets/B2B.svg";
import a from "../assets/a.gif";
import b from "../assets/b.gif";
import c from "../assets/c.gif";
const Corporate = () => {
  return (
    <div className=" bg-[#ffffff] min-h-screen overflow-x-hidden md:mt-28">
      <h3 className="text-3xl sm:text-[56px] opacity-90 mb-2 leading-[70px] sm:leading-[120px] font-Source bg-clip-text bg-[#212529] text-transparent font-bold  text-center">
        Corporate Gifting Solution
      </h3>
      <p className="text-center px-3 md:px-64 text-[18px] text-[#42526B]">
        Elevate your corporate gifting with our comprehensive solutions designed
        to impress clients, reward employees, and strengthen business
        relationships. Our curated selection of high-quality, customizable gifts
        ensures that you can find the perfect item for any occasion.
      </p>
      <div className="flex flex-wrap justify-center border-b-2 sm:justify-center  items-center mt-5  transition-all delay-100">
        <div>
          <img className="lg:w-96" src={b} alt="" />
        </div>
        <div className=" p-4 w-full lg:w-[40%]">
          <h3 className=" text-3xl md:text-4xl text-[#212529] text-center lg:text-left  font-bold font-Source capitalize">
            Client and Partner <br /> Appreciation
          </h3>
          <p className="text-base text-[#42526B] font-Source font-medium text-justify  py-5">
            Strengthen your business relationships by sending thoughtful gifts
            to clients and partners. Whether it’s to celebrate a successful
            project, thank them for their continued partnership, or mark special
            occasions, corporate gifts such as digital gift cards demonstrate
            your appreciation and help build lasting connections.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center border-b-2  items-center   mt-2  transition-all delay-100">
        <div className="p-4 w-full lg:w-[40%]">
          <h3 className="text-3xl md:text-4xl text-[#212529] text-center lg:text-left  font-bold font-Source capitalize">
            Employee Appreciation <br /> and Recognition
          </h3>
          <p className="text-base text-[#42526B] text-justify font-Source font-medium py-5">
            Show your employees that their hard work and dedication are valued
            with thoughtful corporate gifts. Recognize milestones such as work
            anniversaries, project completions, and exceptional performance.
            Offering digital gift cards allows your team to choose their
            preferred rewards, making the appreciation more personal and
            meaningful.
          </p>
        </div>
        <img className="lg:w-96" src={a} alt="" />
      </div>

      <div className="flex justify-center flex-wrap  items-center flex-row-reverse  mt-5  transition-all delay-100">
        <div className=" p-4 w-full lg:w-[40%]">
          <h3 className=" text-3xl md:text-4xl text-[#212529] text-center lg:text-left  font-bold font-Source capitalize">
            Sales Incentives and <br /> Rewards
          </h3>
          <p className="text-base text-[#42526B] font-Source text-justify font-medium py-5">
            Motivate your sales team with performance-based incentives. Reward
            top performers and teams that exceed their targets with digital gift
            cards. This not only drives competition and performance but also
            shows your commitment to recognizing and rewarding hard work.
          </p>
        </div>
        <img className="lg:w-96" src={c} alt="" />
      </div>
    </div>
  );
};

export default Corporate;
