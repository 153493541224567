import React from "react";

const FeatureCard = ({ title, subtext }) => {
  return (
    <div className="p-5  border-t-4   relative z-30 ">
      <h3 className="text-2xl  font-semibold">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 56 56"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.625 11C4.625 7.47919 7.47918 4.625 11 4.625H45C48.5208 4.625 51.375 7.47919 51.375 11V14.5417H4.625V11ZM0.375 16.6667V11C0.375 5.13198 5.13197 0.375 11 0.375H45C50.868 0.375 55.625 5.13198 55.625 11V16.6667V33.6667C55.625 39.5347 50.868 44.2917 45 44.2917H36.5C35.3264 44.2917 34.375 43.3403 34.375 42.1667C34.375 40.9931 35.3264 40.0417 36.5 40.0417H45C48.5208 40.0417 51.375 37.1875 51.375 33.6667V18.7917H4.625V30.8333C4.625 32.0069 3.6736 32.9583 2.5 32.9583C1.3264 32.9583 0.375 32.0069 0.375 30.8333V16.6667ZM31.5417 30.8333C31.5417 29.6597 32.4931 28.7083 33.6667 28.7083H42.1667C43.3403 28.7083 44.2917 29.6597 44.2917 30.8333C44.2917 32.0069 43.3403 32.9583 42.1667 32.9583H33.6667C32.4931 32.9583 31.5417 32.0069 31.5417 30.8333ZM15.9583 30.8333C15.9583 29.6597 15.0069 28.7083 13.8333 28.7083C12.6597 28.7083 11.7083 29.6597 11.7083 30.8333C11.7083 35.919 7.58562 40.0417 2.5 40.0417C1.3264 40.0417 0.375 40.9931 0.375 42.1667C0.375 43.3403 1.3264 44.2917 2.5 44.2917C7.58562 44.2917 11.7083 48.4144 11.7083 53.5C11.7083 54.6736 12.6597 55.625 13.8333 55.625C15.0069 55.625 15.9583 54.6736 15.9583 53.5C15.9583 48.4144 20.081 44.2917 25.1667 44.2917C26.3403 44.2917 27.2917 43.3403 27.2917 42.1667C27.2917 40.9931 26.3403 40.0417 25.1667 40.0417C20.081 40.0417 15.9583 35.919 15.9583 30.8333ZM9.76132 42.1667C11.3939 41.1185 12.7852 39.7272 13.8333 38.0947C14.8815 39.7272 16.2728 41.1185 17.9053 42.1667C16.2728 43.2148 14.8815 44.6061 13.8333 46.2387C12.7852 44.6061 11.3939 43.2148 9.76132 42.1667Z"
            fill="#594DF1"
          />
        </svg>
        {title}
      </h3>
      <p className="text-lg">{subtext}</p>
    </div>
  );
};

export default FeatureCard;
