import React from "react";
import join from "../assets/joinus.gif";
import { Link } from "react-router-dom";
const JoinUs = ({ title, subtext, btntext,link }) => {
  return (
    <div>
      <div className="w-[80%] mx-auto my-10 md:my-32 rounded-xl bg-[#F1ECFF] flex justify-between items-center p-2.5 md:h-96">
        <div className="px-4 md:px-20  md:w-[50%]">
          <div className="flex justify-center items-center  ">
            <h3 className="text-xl md:text-4xl font-semibold text-justify tracking-tight ">
              {title}
              <p className="text-base  pt-3">{subtext}</p>
            </h3>
          </div>
          <div className="flex justify-start mt-4">
            <Link to={link}>
              {" "}
              <button className="text-base font-semibold font-Source text-white bg-[#594DF1] border  rounded-md p-2 px-3">
                {btntext}
              </button>
            </Link>
          </div>
        </div>
        <div className="w-96 hidden md:block">
          <img className="rounded-r-xl" src={join} alt="" />
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
