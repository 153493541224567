import React from "react";

const Testimonial = ({ image, name, review, color }) => {
  return (
    <div>
      <div
        className={`scale-90 md:scale-100 max-w-md h-52 rounded-lg flex items-start gap-2 overflow-hidden shadow-lg my-10  ${color} border-2 p-4 mx-14 `}
      >
        <img className="w-12 h-12 rounded-full " src={image} alt={name} />
        <div className="text-left ">
          <h2 className="text-xl md:text-2xl font-Source font-semibold ">
            {name}
          </h2>
          <p className=" font-Source text-base md:text-base mt-2 ">
            "{review}"
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
