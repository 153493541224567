import React from "react";
import SolutionCard from "./Cards/SolutionCard";
import squid from "../assets/squid.webp";
import cCode from "../assets/credenceCode.svg";
import cCard from "../assets/credenceCard.svg";
const ExploreSolution = () => {
  return (
    <div className="mt-24  ">
      <h3 className="text-3xl md:text-[56px] opacity-90 font-Source  text-[#212529]   font-bold  text-center">
        Explore Our Solution
      </h3>
      <p className="text-center text-base py-4 w-full p-3 md:w-[60%] mx-auto font-Source">
        Explore our solutions for an effortless gifting experience. Our curated
        selection makes finding the perfect present easy and delightful. Enjoy
        seamless, personalized options that simplify every step, from selection
        to delivery.
      </p>
      <div className="flex flex-wrap gap-14 md:gap-4  pt-12 justify-evenly items-center">
        <SolutionCard
          img={squid}
          title={"Squid"}
          subtext={
            "We’ve reinvented gifting and receiving gift cards from a boring to fully personalized and exciting experience."
          }
          link={"/squid"}
        />
        <SolutionCard
          img={cCard}
          title={"Credence Visa Card"}
          subtext={
            "We’ve reinvented gifting and receiving gift cards from a boring to fully personalized and exciting experience."
          }
          link={"/credence-visa-card"}
        />
        <SolutionCard
          img={cCode}
          title={"Credence Code"}
          subtext={
            "We’ve reinvented gifting and receiving gift cards from a boring to fully personalized and exciting experience."
          }
          link={"/credence-code"}
        />
      </div>
    </div>
  );
};

export default ExploreSolution;
