import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/authSlice";


const Drawer = ({ isOpen, setIsOpen, direction }) => {

  const [showModal, setShowModal] = useState(false);
  const dispatch=useDispatch()

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={`fixed inset-0 overflow-hidden z-50 transition-opacity ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-black bg-opacity-75 transition-opacity"></div>
        </div>
        <div
          className={`relative ${direction === "left" ? "left-0" : "right-0"}`}
        >
          <div
            className={`${
              direction === "left" ? "left-0" : "right-0"
            } fixed h-full flex flex-col justify-start items-start bg-white w-64 md:w-80 lg:w-80 transition-transform transform ${
              isOpen
                ? "translate-x-0"
                : direction === "left"
                ? "-translate-x-full"
                : "translate-x-full"
            }`}
          >
            <div className="p-2 flex items-center justify-between w-full">
              <div className="flex items-center">
                <img
                  className="h-7 w-auto ml-20"
                  src="../../assets/axis-bank.png"
                  alt="Your Company"
                />
              </div>

              <div
                className="text-3xl font-bold cursor-pointer"
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="0.8em"
                  viewBox="0 0 384 512"
                  fill="#444546"
                  onClick={handleClose}
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
                {/* Close icon (you can use any other SVG or font icon) */}
              </div>
            </div>

            <div
              className="text-gray-500  mt-2 cursor-pointer w-full"
              onClick={handleClose}
            >
              {/* <ContactSupportIcon /> */}

              <div className="inline-block mb-2 mt-5">
                <a href="mailto:support@credencerewards.com">Contact Support</a>
              </div>
            </div>
            <div className="border-t border-gray-200 w-full my-2"></div>
            <div
              className="text-gray-500 mt-2 cursor-pointer w-full"
              // onClick={handleClose}
            >
              {/* <TermsIcon /> */}
              <div
                className="inline-block mb-2"
                onClick={() =>dispatch(login()) }
              >
                Terms & Conditions
              </div>
              <div className="border-t border-gray-200 w-full my-2"></div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default Drawer;
