import React from "react";
import NavBar from "./NavBar";
import { useSelector } from "react-redux";

function Congratulation() {
  const details = useSelector((state) => state?.finalDetail?.data?.data);
  const image = useSelector((state) => state?.finalDetail?.image);

  function copycode(text) {
    try {
      navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  }
  return (
    <>
      <div className="fixed top-0 w-full relative ">
        <NavBar />
      </div>
      <div className="flex justify-center mt-20  my-20 flex-col items-center">
        <h2 className="md:text-4xl font-extrabold text-xl">Congratulations!</h2>
        <h5 className="text-center mt-5 mb-0 text-xl">Your voucher details</h5>
        <div className="mb-10 mt-0 md:w-4/6  flex justify-center items-center w-full">
          <div className="">
            <div className=" mb-4 border border-black rounded-xl  w-96  h-80 px-4 py-2">
              <div className="flex flex-row justify-center my-5">
                <h2 className="text-xl font-semibold"></h2>
                <img
                  src={image}
                  alt="CouponImage"
                  className="w-36 h-50 mr-4 rounded object-cover"
                />
              </div>
              <div className="flex flex-row justify-between mb-1">
                <span className="font-weight-500">
                  Code : {details?.voucher?.code}
                </span>
                <a
                  onClick={() => copycode(details?.voucher?.code)}
                  title="Copy Code"
                  className=" p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                  </svg>
                </a>
              </div>
              <div className="flex flex-row justify-between mb-1 ">
                <span className="font-weight-500">
                  Pin : {details?.voucher?.pin}
                </span>
                <a
                  onClick={() => copycode(details?.voucher?.code)}
                  title="Copy Code"
                  className="p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                  </svg>
                </a>
              </div>
              <div className="flex flex-row justify-between ">
                <span className="font-weight-500">
                  Expiry : {details?.voucher?.expiry}
                </span>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-xl">Vocher Sent on Email and SMS</h2>
        <div>
          <p className="text-slate-500">
            Need help email us at{" "}
            <a href="#" className="text-blue-500">
              support@credencerewards.com
            </a>
          </p>
        </div>
      </div>
      s
    </>
  );
}

export default Congratulation;
