import React, { useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout";
import EnquiryForm from "../Components/EnquiryForm";
import HelmetWrapper from "../Components/HelmetWrapper";
const Enquiry = () => {
  return (
    <MainLayout>
      <HelmetWrapper
        title="Enquiry - Credence Rewards"
        description="Have questions or need assistance? Reach out to Credence Rewards through our enquiry page. We're here to help with any questions or support you need regarding our rewards program. Contact us today!"
        keywords="Credence Rewards enquiry, contact Credence Rewards, rewards program support, customer service, questions, assistance, help, reach out"
        ogTitle="Enquiry - Credence Rewards"
        ogDescription="Have questions or need assistance? Reach out to Credence Rewards through our enquiry page. We're here to help with any questions or support you need regarding our rewards program. Contact us today!"
        ogUrl="https://credencerewards.co.in/enquiry"
        ogImage=""
        twitterTitle="Enquiry - Credence Rewards"
        twitterDescription="Have questions or need assistance? Reach out to Credence Rewards through our enquiry page. We're here to help with any questions or support you need regarding our rewards program. Contact us today!"
        twitterImage=""
        canonicalUrl="https://credencerewards.co.in/enquiry"
      />
      <div className="py-12">
        <h1 className=" text-2xl lg:text-6xl text-indigo-800 font-semibold  text-center">
          Buy gift cards for your business
        </h1>
        <h2 className="text-center text-2xl lg:text-6xl pt-3">
          {" "}
          from 200+ Brands
        </h2>
        <EnquiryForm />
      </div>
    </MainLayout>
  );
};

export default Enquiry;
