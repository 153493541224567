import React from "react";
import MainLayout from "../layout/MainLayout";
import hero from "../assets/aboutus.webp";
import office from "../assets/office.jpeg";
import dg from "../assets/digitalgift.png";
import bullk from "../assets/bullk.png";
import eet from "../assets/eet.png";
import convi from "../assets/convi.png";
import choices from "../assets/choices.png";
import security from "../assets/security.png";
import management from "../assets/management.png";
import commitment from "../assets/commitment.png";
import corporate from "../assets/corporate.webp";
import JoinUs from "../Components/JoinUs";
import HelmetWrapper from "../Components/HelmetWrapper";
const Aboutus = () => {
  return (
    <MainLayout>
      <HelmetWrapper
        title="About Us - Credence Rewards"
        description="Learn more about Credence Rewards, our mission, values, and the team dedicated to providing the best rewards program. Discover how we help our users earn and redeem points for exciting rewards."
        keywords="about us, Credence Rewards, corporate reward solutions, digital gift
        cards, rewards program, popular brands, redeem rewards, team"
        ogTitle="About Us - Credence Rewards"
        ogDescription="Learn more about Credence Rewards, our mission, values, and the team dedicated to providing the best rewards program. Discover how we help our users earn and redeem points for exciting rewards."
        ogUrl="https://credencerewards.co.in/about-us"
        ogImage={hero} // Replace with the actual path to your image
        twitterTitle="About Us - Credence Rewards"
        twitterDescription="Learn more about Credence Rewards, our mission, values, and the team dedicated to providing the best rewards program. Discover how we help our users earn and redeem points for exciting rewards."
        twitterImage={hero} // Replace with the actual path to your image
        canonicalUrl="https://credencerewards.co.in/about-us"
      />
      <h1 className=" relative text-3xl md:text-6xl text-center py-5 font-extrabold">
        About Us
      </h1>
      <p className="text-justify relative  text-lg md:text-xl  text-black w-[75%] rounded-3xl mx-auto px-1 md:px-14 py-4  mb-5 font-semibold  leading-8 -tracking-tighter font-Source">
        Welcome to Credence Rewards, your premier destination for digital gift
        cards and corporate reward solutions. At Credence Rewards, we believe in
        the power of appreciation and the impact it can have on both personal
        and professional relationships. Whether you're an individual looking for
        the perfect gift or a business seeking to recognize and motivate your
        employees, we have the solutions you need.
      </p>
      <div className="py-5">
        {" "}
        <div
          className="relative w-[95%] md:w-[70%] h-[80vh] md:h-[50vh] rounded-3xl pt-14 md:scale-80"
          style={{
            backgroundImage: `url(${hero})`,
            backgroundSize: "cover",
            backgroundPosition: "center",

            margin: "auto",

            position: "relative", // Example height, adjust as needed
          }}
        >
          {/* <div className="absolute z-10 top-0 left-0 w-full h-full rounded-t-3xl  bg-gradient-to-b from-indigo-950/95 to-yellow-600/0"></div> */}
        </div>
        <div className="py-24">
          <h2 className="text-5xl text-center font-semibold">Who Are We</h2>
          <div className="flex flex-wrap justify-center items-center mt-10 gap-12 px-2 md:px-64">
            <div className="w-full text-justify text-lg">
              Credence Rewards is a leading provider of digital gift cards,
              offering a wide range of options from the most popular brands and
              retailers. Founded on the principles of convenience, choice, and
              security, we are dedicated to delivering a seamless gifting
              experience for our customers. Our team of experts continuously
              works to expand our offerings and enhance our platform, ensuring
              that you have access to the best digital gift card options
              available.
            </div>

            <img
              className="w-[80%] md:w-[45%] rounded-lg"
              src={office}
              alt=""
            />
          </div>
        </div>
        <div className="py-24">
          <h2 className="text-5xl text-center font-semibold">Our Mission</h2>
          <div className="flex justify-center items-center mt-10 gap-12 px-2 md:px-64">
            <div className="w-full text-justify text-lg">
              Our mission is simple: to make gifting easy, enjoyable, and
              impactful. We strive to provide a diverse selection of digital
              gift cards that cater to all tastes and preferences, ensuring that
              every recipient feels valued and appreciated. For our corporate
              clients, we aim to create bespoke reward solutions that drive
              employee engagement, boost morale, and foster a culture of
              recognition and excellence.
            </div>
          </div>
        </div>
        <div className="py-24">
          <h2 className="text-5xl text-center font-semibold">What We Offer</h2>
          <div className="flex flex-wrap  md:flex-nowrap justify-center items-center mt-10 gap-12 px-4 md:px-64">
            <div>
              <h2 className="text-2xl font-semibold py-2">Digital Gift Card</h2>
              <div className="w-full text-justify text-lg">
                Our extensive catalog of digital gift cards includes options
                from top brands across various categories such as retail,
                dining, entertainment, travel, and more. Whether you're
                celebrating a birthday, holiday, or special milestone, Credence
                Rewards has the perfect gift card for every occasion.
              </div>
            </div>
            <img className="w-[70%] md:w-[35%] rounded-lg" src={dg} alt="" />
          </div>
          <div className="flex flex-wrap md:flex-nowrap flex-row-reverse justify-center items-center mt-10 gap-12 px-4 md:px-64">
            <div>
              <h2 className="text-2xl font-semibold py-2">
                Corporate Solutions
              </h2>
              <div className="w-full text-justify text-lg">
                Credence Rewards offers tailored corporate solutions designed to
                meet the unique needs of businesses of all sizes.
              </div>
            </div>
            <img
              className="w-[70%] md:w-[40%] rounded-lg"
              src={corporate}
              alt=""
            />
          </div>
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-14 w-[70%] lg:w-[60%] mx-auto">
            <div className="flex flex-row-reverse justify-center items-center mt-10 gap-12  ">
              <div className="border-2 p-3 rounded-lg">
                <h2 className="text-2xl font-semibold py-2">Bullk gifting</h2>
                <div className="w-full text-justify text-base">
                  Simplify your reward and recognition programs with easy bulk
                  purchases of digital gift cards.
                </div>
              </div>
              {/* <img className="w-[30%] rounded-lg" src={bullk} alt="" /> */}
            </div>
            <div className="flex  justify-center items-center mt-10 gap-12 ">
              <div className="border-2 p-3 rounded-lg">
                <h2 className="text-2xl font-semibold py-2">
                  Employee Engagement Tools
                </h2>
                <div className="w-full text-justify text-base">
                  Utilize our platform to manage and track employee rewards,
                  ensuring timely and meaningful recognition.
                </div>
              </div>
              {/* <img
                className="w-[30%] rounded-lg border border-indigo-500"
                src={eet}
                alt=""
              /> */}
            </div>
          </div>
        </div>
        <div className="pt-24 pb-10">
          <h2 className=" text-4xl md:text-5xl text-center font-semibold">
            Why Choose Credence Rewards?
          </h2>
          <div className="flex justify-center flex-wrap gap-10 px-10 md:px-28 mt-20">
            <div className="w-96 border-4 rounded-xl p-3 px-5">
              <img className="w-[20%] mx-auto" src={convi} alt="" />
              <h3 className="text-center text-2xl font-semibold py-1">
                Convenience
              </h3>
              <p className="text-justify">
                Our user-friendly platform makes it easy to browse, purchase,
                and send digital gift cards from the comfort of your home or
                office. Instant delivery via email ensures that your gift
                reaches the recipient without delay.
              </p>
            </div>
            <div className="w-96 border-4 rounded-xl p-3 px-5">
              <img className="w-[20%] mx-auto" src={choices} alt="" />
              <h3 className="text-center text-2xl font-semibold py-1">
                Choice
              </h3>
              <p className="text-justify">
                With a vast selection of digital gift cards from leading brands,
                you’ll always find the perfect gift for any occasion. Our
                continuously expanding catalog ensures fresh and exciting
                options.
              </p>
            </div>
            <div className="w-96 border-4 rounded-xl p-3 px-5">
              <img className="w-[20%] mx-auto" src={security} alt="" />
              <h3 className="text-center text-2xl font-semibold py-1">
                security
              </h3>
              <p className="text-justify">
                We prioritize the security of your transactions. Our platform
                uses advanced encryption technology to protect your personal and
                payment information, ensuring a safe and secure shopping
                experience.
              </p>
            </div>
            <div className="w-96 border-4 rounded-xl p-3 px-5">
              <img className="w-[20%] mx-auto" src={management} alt="" />
              <h3 className="text-center text-2xl font-semibold py-1">
                management
              </h3>
              <p className="text-justify">
                With years of experience in providing corporate reward
                solutions, we understand the importance of timely and impactful
                recognition. Our dedicated account managers work closely with
                you to develop and implement reward programs that resonate with
                your team.
              </p>
            </div>
            <div className="w-96 border-4 rounded-xl p-3 px-5">
              <img className="w-[20%] mx-auto" src={commitment} alt="" />
              <h3 className="text-center text-2xl font-semibold py-1">
                commitment
              </h3>
              <p className="text-justify">
                At Credence Rewards, we are committed to delivering excellence
                in every aspect of our service. From the quality of our digital
                gift cards to the support we provide our customers, we strive to
                exceed expectations and foster lasting relationships.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <JoinUs
            title={"What Are You Waiting For?"}
            subtext={
              "Join the Credence Rewards community today and experience the joy of giving and receiving with ease and confidence."
            }
            btntext={"Start Gifting"}
            link={"/enquiry"}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Aboutus;
