// HelmetWrapper.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetWrapper = ({ 
  title, 
  description, 
  keywords, 
  ogTitle, 
  ogDescription, 
  ogUrl, 
  ogImage, 
  twitterTitle, 
  twitterDescription, 
  twitterImage, 
  canonicalUrl 
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    
    {/* Open Graph Meta Tags */}
    <meta property="og:title" content={ogTitle || title} />
    <meta property="og:description" content={ogDescription || description} />
    <meta property="og:url" content={ogUrl || canonicalUrl} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:type" content="website" />
    
    {/* Twitter Meta Tags */}
    <meta name="twitter:title" content={twitterTitle || title} />
    <meta name="twitter:description" content={twitterDescription || description} />
    <meta name="twitter:image" content={twitterImage} />
    <meta name="twitter:card" content="summary_large_image" />
    
    {/* Canonical Tag */}
    <link rel="canonical" href={canonicalUrl} />
  </Helmet>
);

export default HelmetWrapper;
