import React from "react";
import MainLayout from "../layout/MainLayout";
// import Nsdl3d from "../Components/Nsdl3d";
import FeatureCard from "../Components/Cards/FeatureCard";
import nsdlhero from "../assets/nsdlhero.webp";
import "../App.css";
import gift from "../assets/nsdlgift.png";
import { Link } from "react-router-dom";
import HelmetWrapper from "../Components/HelmetWrapper";
const Nsdlcard = () => {
  return (
    <MainLayout>
      <HelmetWrapper
        title="Credence Visa Card - Exclusive Benefits and Rewards"
        description="Discover the Credence Visa Card and unlock a world of exclusive benefits and rewards. Earn points on every purchase and redeem them for exciting offers. Apply now and start enjoying the perks!"
        keywords="Credence Visa Card, Visa card benefits, NSDL Payments Bank, digital transactions, exclusive offers, corporate customer, credit card rewards, Credence Rewards"
        ogTitle="Credence Visa Card - Exclusive Benefits and Rewards"
        ogDescription="Discover the Credence Visa Card and unlock a world of exclusive benefits and rewards. Earn points on every purchase and redeem them for exciting offers. Apply now and start enjoying the perks!"
        ogUrl="https://credencerewards.co.in/credence-visa-card"
        ogImage={nsdlhero} // Replace with the actual path to your image
        twitterTitle="Credence Visa Card - Exclusive Benefits and Rewards"
        twitterDescription="Discover the Credence Visa Card and unlock a world of exclusive benefits and rewards. Earn points on every purchase and redeem them for exciting offers. Apply now and start enjoying the perks!"
        twitterImage={nsdlhero} // Replace with the actual path to your image
        canonicalUrl="https://credencerewards.co.in/credence-visa-card"
      />
      <div className="w-[90%]"></div>

      {/* <div className="flex relative w-full">
        <div className="bg-[#0B63E5]/20  blur-[100px] absolute -top-64 z-10 left-0 w-96 h-96 rounded-full"></div>
        <div className="bg-[#F249C8]/20  absolute blur-[100px] -top-64  left-[45%]  z-10 right-[50%] w-96 h-96 rounded-full"></div>
        <div className="bg-[#F249C8]/20  absolute blur-[100px] -top-64  left-[25%]  z-10 right-[40%] w-96 h-96 rounded-full"></div>
        <div className="bg-[#0B63E5]/20  absolute blur-[100px] -top-64 right-0 w-96  z-10 h-96 rounded-full"></div>
      </div> */}
      <div className=" flex justify-evenly flex-wrap lg:flex-nowrap mt-12 relative z-30 ">
        <div className="px-2 md:px-28 w-[99%]">
          <h1 className="text-3xl md:text-6xl text-center mt-6 md:mt-20 lg:text-left font-semibold">
            Credence Visa Card by NSDL Payments Bank
          </h1>

          <div className="text-justify text-base lg:text-xl   w-fit  py-9 rounded-xl mt-9 font-semibold ">
            Experience the convenience and security of the Credence Visa Card,
            designed to meet all your payment needs, whether you're an
            individual or a corporate customer. Say goodbye to cash payments and
            embrace the freedom of digital transactions with the Credence Visa
            Card. Accepted at POS, E-commerce platforms, and ATMs across India,
            this card is your perfect companion for seamless financial
            management.
          </div>
        </div>
        <div>
          <img className="w-96 lg:w-full" src={nsdlhero} alt="Credence Visa Card by NSDL Payments Bank" />
          {/* <Nsdl3d /> */}
        </div>
      </div>
      <div className="bg-[#F8F8FE] py-14 relative z-30">
        <div className="text-center text-4xl md:text-5xl font-semibold mb-3 ">
          Key Features
        </div>
        <p className="text-center px-5 md:px-52 font-semibold text-xl md:text-2xl  relative z-40">
          Experience the convenience and security of the Credence Visa Card,
          designed to meet all your payment needs, whether you're an individual
          or a corporate customer.
        </p>
        <div
          className="flex justify-center flex-wrap md:flex-nowrap gap-12 py-20 items-start
         overflow-x-hidden no-scrollbar px-6 md:px-40  mt-12 "
        >
          {/* <Nsdl3d /> */}
          <FeatureCard
            title={"No Bank Account Required"}
            subtext={"Get started without the need for a bank account."}
          />
          <FeatureCard
            title={"ZERO Card Issuance Fee"}
            subtext={"Enjoy the benefit of no card issuance charges."}
          />
          <FeatureCard
            title={"E-Commerce Transactions Enabled"}
            subtext={"Shop online effortlessly with your Credence Visa Card."}
          />{" "}
          <FeatureCard
            title={"Accepted at Most Merchant Outlets"}
            subtext={
              "Use your card at a vast network of merchants nationwide. "
            }
          />{" "}
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-12 py-12  items-center ">
        <div className="md:w-[30%] px-4 sm:px-0">
          <h2 className="text-4xl font-semibold py-3">Gift Cards</h2>
          <p>
            Celebrate the special moments in life with a gift that speaks
            volumes. Our gift cards offer the perfect way to show your
            appreciation, love, and care.
          </p>
          <ul>
            <li className="flex justify-start gap-2 py-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Perfect for special occasions and celebrations
            </li>
            <li className="flex justify-start gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Monitor your balance and track spending through our online portal
            </li>
            <li className="flex justify-start gap-2 py-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Quick and easy KYC process to get started.
            </li>
          </ul>
          <button className="text-base mt-4 font-semibold font-Source text-white bg-[#594DF1] border  rounded-md p-2.5 px-3">
            know more
          </button>
        </div>
        <div>
          <img src={gift} alt="" />
        </div>
      </div>
      <div className="flex flex-wrap justify-center flex-row-reverse py-24 bg-[#f8f8fe] gap-12 items-center ">
        <div className="md:w-[30%] px-4 sm:px-0">
          <h2 className="text-4xl font-semibold py-3">
            General Purpose Reloadable Cards
          </h2>
          <p>
            With features like easy reload options, wide acceptance at
            merchants, and enhanced security measures, these cards provide a
            reliable and convenient way to make purchases, pay bills, and
            withdraw cash.
          </p>
          <ul>
            <li className="flex justify-start gap-2 py-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Access your cash at ATMs nationwide
            </li>
            <li className="flex justify-start gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Suitable for all your payment needs, whether online or offline
            </li>
            <li className="flex justify-start gap-2 py-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="0 0 20 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 6.16663C16.1023 7.4192 17.0807 8.39765 18.3333 8.66663C17.0807 8.9356 16.1023 9.91405 15.8333 11.1666C15.5643 9.91405 14.5859 8.9356 13.3333 8.66663C14.5859 8.39765 15.5643 7.4192 15.8333 6.16663ZM7.56 6.3333C8.31958 9.87056 11.0827 12.6337 14.62 13.3933C11.0827 14.1529 8.31958 16.916 7.56 20.4533C6.80042 16.916 4.03726 14.1529 0.5 13.3933C4.03726 12.6337 6.80042 9.87056 7.56 6.3333ZM18.3333 19.5C16.6632 19.1413 15.3586 17.8367 15 16.1666C14.6413 17.8367 13.3367 19.1413 11.6666 19.5C13.3367 19.8586 14.6413 21.1632 15 22.8333C15.3586 21.1632 16.6632 19.8586 18.3333 19.5Z"
                  fill="#594DF1"
                />
              </svg>
              Conveniently reload your card for continuous usage
            </li>
          </ul>
          <button className="text-base mt-4 font-semibold font-Source text-white bg-[#594DF1] border  rounded-md p-2.5 px-3">
            know more
          </button>
        </div>
        <div className="w-96 lg:w-[30%]">
          <img className="lg:w-[80%]" src={nsdlhero} alt="" />
        </div>
      </div>
      <div className="flex  flex-wrap justify-center gap-20 items-center p-4 lg:p-28">
        <div className="bg-[#F0F5FF]  w-96 p-9 px-10 rounded-md">
          {" "}
          <h2 className="text-4xl font-semibold py-3">Balance Enquiry</h2>
          <p>Check your balance anytime by clicking here</p>
          <a
            href={"https://prepaid.nsdlbank.co.in/"}
            alt="Check balance"
            target="_blank"
          >
            {" "}
            <button className="text-base mt-4 font-semibold font-Source bg-white border  rounded-md p-2.5 px-3">
              know more
            </button>
          </a>
        </div>
        <div className="bg-[#FEEDFA]  w-96 p-9 px-10 rounded-md">
          {" "}
          <h2 className="text-4xl font-semibold py-3">Customer Support</h2>
          <p>
            For any assistance or queries, please contact our customer care
            team.
          </p>
          <button className="text-base mt-4 font-semibold font-Source  border bg-white  rounded-md p-2.5 px-3">
            <Link to={"/contact-us"}> mail us </Link>
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default Nsdlcard;
