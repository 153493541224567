import React,{useEffect} from "react";
import logo from "../assets/CR Logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, []);
  return (
    <div className="bg-[#594DF1]   lg:p-5 gap-10 flex flex-wrap items-start justify-evenly relative z-0">
      <div className="">
        <img className="w-[10rem] lg:w-[10rem] mx-auto" src={logo} alt="" />
        <p className="lg:w-96 text-center text-base pt-7 text-white">
          We believe that there’s a perfect gift for everyone. Gift your loved
          ones the freedom of choice to select a product they truly desire.
        </p>
      </div>
      <div className="flex flex-col items-start justify-between gap-12">
       <Link to={"/about-us"}> <p className="text-white cursor-pointer text-base md:text-lg font-semibold">
         About US
        </p></Link>
        <p className="text-white text-base md:text-lg font-semibold"><Link to={"/squid"}>Squid</Link></p>
        <p className="text-white text-base md:text-lg font-semibold"><Link to={"/credence-visa-card"}>NSDL</Link></p>
        {/* <p className="text-white text-base md:text-lg font-semibold">
          Credence Code
        </p> */}
      </div>
      {/* <div className="flex flex-col items-start justify-between gap-12">
        <p className="text-white text-base md:text-lg font-semibold">
          Privacy Policy
        </p>
        <p className="text-white text-base md:text-lg font-semibold">
          Terms and Conditions
        </p>
        <p className="text-white text-base md:text-lg font-semibold">Faqs</p>
      </div> */}
    </div>
  );
};

export default Footer;
